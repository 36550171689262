<template>
    <div class="table-responsive scrollbar">
        <table class="table table-hover condensed">
            <thead>
            <tr>
                <th scope="col">Crypto</th>
                <th scope="col">Long/Short</th>
                <th scope="col">Entry Date</th>
                <th scope="col">Average Entry Price</th>
                <th scope="col" v-if="isOpen">Stop</th>
                <th scope="col" v-if="isOpen">Target</th>
                <th scope="col" v-if="isClosed">Average Exit Price</th>
                <th scope="col" v-if="isClosed">Exit Date</th>
                <th scope="col">Recommended % of Portfolio</th>
                <th scope="col" v-if="isClosed">Profit %</th>
                <th scope="col"></th>
            </tr>
            </thead>
            <tbody class="border-top-0">
            <tr v-for="position in positions">
                <td>{{ position.from_ticker }}/{{ position.to_ticker }}</td>
                <td>{{ sides[position.side] }}</td>
                <td>{{ position.opened_at | date('D MMM YYYY') }}</td>
                <td>{{ position.average_entry_price | currency }}</td>
                <td v-if="isOpen">{{ position.stop | currency }}</td>
                <td v-if="isOpen">{{ position.target | currency }}</td>
                <td v-if="isClosed">{{ position.average_exit_price | currency }}</td>
                <td v-if="isClosed">{{ position.closed_at | date('D MMM YYYY') }}</td>
                <td>
                    {{ position.portfolio_percentage }}% or less
                    <small v-if="isOpen && position.open_percentage < position.portfolio_percentage">
                        <br>{{ position.portfolio_percentage - position.open_percentage }}% closed
                    </small>
                </td>
                <td v-if="isClosed" :class="profitClass(position)">{{ position.profit_percentage | currency }}%</td>
                <td style="padding-top: 0.4em; padding-bottom: 0.4em">
                    <BDropdown right variant="link" no-caret size="sm" v-if="isOpen">
                        <template #button-content>
                            <span class="material-symbols-rounded align-middle">more_vert</span>
                        </template>
                        <BDropdownItemButton @click="createClosePositionAlert(position)">
                            Close position
                        </BDropdownItemButton>
                    </BDropdown>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { PositionStatus } from '@/store/models/position-status';
import { PositionSide } from '@/store/models/position-side';
import { formatCurrency } from '@/filters/currency.filter';
import { formatDate } from '@/filters/date.filter';
import { BDropdown, BDropdownItemButton } from 'bootstrap-vue';

export default {
  props: ['section'],
  components: {
    BDropdown,
    BDropdownItemButton,
  },
  filters: {
    currency: formatCurrency,
    date: formatDate,
  },
  computed: {
    ...mapState({
      formAlertId: (state) => state.alerts.formAlertId,
    }),
    ...mapGetters({
      positions: 'positions/all',
    }),
    sides() {
      return PositionSide.labels;
    },
    isClosed() {
      return PositionStatus.routeMap[this.section] === PositionStatus.CLOSED;
    },
    isOpen() {
      return PositionStatus.routeMap[this.section] === PositionStatus.OPEN;
    },
  },
  watch: {
    '$route.params.section'() {
      this.getPositions();
    },
  },
  mounted() {
    this.getPositions();
  },
  methods: {
    getPositions() {
      const status = PositionStatus.routeMap[this.section];
      this.$store.commit('positions/status', status);
      this.$store.dispatch('positions/getPositions', { status });
    },
    profitClass(position) {
      if (position.profit_percentage === null) {
        return null;
      }
      return position.profit_percentage > 0 ? 'bg-success-accent' : 'bg-danger-accent';
    },
    createClosePositionAlert(position) {
      this.$store.dispatch('alerts/newClosePositionAlert', position);
    },
  },
};
</script>
