<template>
    <div>
        <div class="row justify-content-center">
            <div class="col-xxl-10 d-flex justify-content-between align-items-center mb-4 px-3">
                <h5 class=" mb-0 text-secondary">
                    <span class="text-capitalize">{{ section }}</span>  positions
                </h5>
                <ServiceSelect @input="resetPositions"/>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-xxl-10">
                <PositionsList :section="section"/>
            </div>
        </div>
    </div>
</template>

<script>

import PositionsList from '@/components/positions/PositionsList';
import ServiceSelect from '@/components/products/ServiceSelect';

export default {
  components: {
    PositionsList,
    ServiceSelect,
  },
  computed: {
    section() {
      return this.$route.params.section;
    },
  },
  mounted() {
    this.resetPositions();
  },
  methods: {
    async resetPositions() {
      await this.$store.dispatch('positions/terminate');
      await this.$store.dispatch('positions/getPositions');
    },
  },
};
</script>
