<template>
    <AppSelect :value="service"
               :options="services"
               v-if="product"
               field="id"
               @input="updateServiceId"
               :allow-empty="true"
               placeholder="Select service"
               track-by="id"
               label-field="name"
               class="multiselect--sm"/>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import AppSelect from '@/components/form/AppSelect';

export default {
  components: {
    AppSelect,
  },
  computed: {
    ...mapState({
      serviceId: (state) => state.products.serviceId,
    }),
    ...mapGetters({
      product: 'products/product',
    }),
    services() {
      return this.product.services
        ? Object.keys(this.product.services)
          .map((key) => ({
            id: key,
            name: this.product.services[key],
          }))
        : [];
    },
    service() {
      return this.serviceId
        ? {
          id: this.serviceId,
          name: this.product.services[this.serviceId],
        }
        : null;
    },
  },
  methods: {
    updateServiceId(service) {
      this.$store.commit('products/serviceId', service ? service.id : null);
      this.$emit('input');
    },
  },
};
</script>
